import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index.vue'
import Sto from '../views/sto/sto.vue'
import StoByu1 from "../views/sto/sto-buy1.vue"
import Ido from "../views/Ido/Ido.vue"
import History from "../views/history/history.vue"
import Statistics from "../views/statistics/statistics.vue"
import Pledge from "../views/pledge/pledge.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/sto',
        name: 'sto',
        component: Sto
    },
    {
        path: '/stoByu1',
        name: 'stoByu1',
        component: StoByu1
    },
    {
        path: '/ido',
        name: 'ido',
        component: Ido
    },
    {
        path: '/history',
        name: 'history',
        component: History
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: Statistics
    },
    {
        path: '/pledge',
        name: 'pledge',
        component: Pledge
    },
]

const router = new VueRouter({
    routes
})

export default router
