<template>
    <div>
        <div>
            <van-nav-bar :title="message" left-arrow @click-left="onClickLeft" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'vant'
export default {
    name: 'Index',
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    // 组件
    components: {},
    data() {
        return {}
    },
    computed: {},
    async created() {},
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
    },
}
</script>
<style>
@import './style/historyGo.css';
</style>
