import BigNumber from "bignumber.js";
// web3 公共库
export default class UniSwap{

    approve_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';  //设置授权数量

    constructor(contract,abi,contractAddress,userAddress){
        this.contract = contract;
        this.abi = abi;
        this.contractAddress = contractAddress;
        this.userAddress = userAddress;
        if(this.contract && this.abi && this.contractAddress && this.userAddress){
            this.methods = new this.contract.Contract(this.abi,this.contractAddress,{from: this.userAddress}).methods
        }
    }
    // 获得A -> B时，tokenA_Amount个 A 可以兑换 new BigNumber(amountsOutResult[1]).div(new BigNumber(10).pow(tokenBDecimal)).toNumber()个 B
    async getAmountsOut(tokenA,tokenADecimal,tokenB,tokenBDecimal,tokenA_Amount) {
        try {
            let amountsOutResult = await this.methods.getAmountsOut( new BigNumber(tokenA_Amount).times(new BigNumber(10).pow(tokenADecimal)).toFixed(0),[tokenA,tokenB]).call();
            let lowetAmountsOutResult = await this.methods.getAmountsOut( new BigNumber(0.01).times(new BigNumber(10).pow(tokenADecimal)).toFixed(0),[tokenA,tokenB]).call();
            if(amountsOutResult && lowetAmountsOutResult) {
                // 计算滑点
                let priceImpact = 0;
                let fullAmount = new BigNumber(lowetAmountsOutResult[1]).times(tokenA_Amount * 100);
                if(fullAmount.gt(amountsOutResult[1])) {
                    priceImpact = fullAmount.minus(amountsOutResult[1]).div(fullAmount).toNumber();
                }
                return [new BigNumber(amountsOutResult[1]).div(new BigNumber(10).pow(tokenBDecimal)).toNumber(),priceImpact];
            }
        } catch(err) {
            console.log(123)
            console.log(err)
        }
    }

    // 获得A -> B 时，买tokenB_Amount个 B 时需要new BigNumber(amountsInResult[0]).div(new BigNumber(10).pow(tokenADecimal)).toNumber()个 A
    async getAmountsIn(tokenA,tokenADecimal,tokenB,tokenBDecimal,tokenB_Amount) {
        try {
            let amountsInResult = await this.methods.getAmountsIn(new BigNumber(tokenB_Amount).times(new BigNumber(10).pow(tokenBDecimal)).toFixed(0),[tokenA,tokenB]).call();
            if(amountsInResult) {
                let priceImpact = 0;
                let lowetAmountsOutResult = await this.methods.getAmountsOut(new BigNumber(0.01).times(new BigNumber(10).pow(tokenADecimal)).toFixed(0),[tokenA,tokenB]).call();
                if(lowetAmountsOutResult) {
                    // 计算滑点
                    let fullAmount = new BigNumber(lowetAmountsOutResult[1]).times(new BigNumber(amountsInResult[0]).div(new BigNumber(10).pow(tokenADecimal))).times(100);
                    if(fullAmount.gt(amountsInResult[1])) {
                        priceImpact = fullAmount.minus(amountsInResult[1]).div(fullAmount).toNumber();
                    }
                }
                return [new BigNumber(amountsInResult[0]).div(new BigNumber(10).pow(tokenADecimal)).toNumber(), priceImpact];
            }
        } catch(err) {
            console.log(err)
        }
    }
} 