import Vue from "vue";
import Web3 from "web3";
import MainWallet from "@/components/wallet/mainWallet";
export default class BasicWallet extends MainWallet{
  /**
   * 钱包基础
   */
  initSuccess = false; // true 表示 初始化完成并且成功
  provider = null; // web3 的provider
  walletUnlock = false; // true 环境已经被注入成功，可以进行初始化
  browserWindow = window;

  // 钱包初始化
  async init(){
    return new Promise((resolve) => {
			if (this.initSuccess) {
				return resolve(new Web3());
			}

			let timer = setInterval(async () => {
				if (this.walletUnlock) {
					clearInterval(timer);
					this.web3 = new Web3(this.provider);
					if (this.web3) {
						let accountResult = await this.web3.eth.getAccounts();
						if (accountResult && accountResult[0]) {
							this.userAddress = accountResult[0];
							this.initSuccess = true;
              // 挂载全局
              Vue.prototype.web3 = this
						}
					}

					// if (this.onInitFinished()) {
					// 	this.onInitFinished();
					// }
                    this.onInitFinished();
					return resolve(new Web3())
				}

				if (this.browserWindow.ethereum) {
					this.provider = this.browserWindow.ethereum;
					try {
						await this.browserWindow.ethereum.enable();
						this.walletUnlock = true;
					} catch (error) {
						resolve(this.walletUnlock)
						console.log("User denied account access");
					}
				} else if (this.browserWindow.web3) {
					this.provider = this.browserWindow.web3.currentProvider;
					this.walletUnlock = true;
				} else {
					resolve(this.walletUnlock)
					console.log("Can not find web3 providers");
				}
			}, 200);
		})
  }
  async onInitFinished() {
    await this.initOnInitFinished(this)
  }
}