<template>
    <div class="home-head">
        <div class="head">
            <div class="logo"><img src="./img/logo.png" alt="" /></div>
            <div class="userAddress">
                <div class="home-boer"></div>
                {{
                    web3.userAddress
                        ? this.web3.userAddress.substring(0, 4) +
                          '...' +
                          this.web3.userAddress.substring(
                              this.web3.userAddress.length - 4,
                              this.web3.userAddress.length
                          )
                        : '...'
                }}
            </div>
            <div class="language-btn">
                <img
                    style="height: 20px; width: 20px; margin-right: 20px"
                    src="./img/ty.png"
                    alt=""
                />
                <img
                    style="height: 20px; width: 20px"
                    src="./img/zh.png"
                    alt=""
                    @click="changeLanguage()"
                />
                <!-- <div @click="changeLanguage()">{{ this.language }}</div> -->
            </div>
            <van-dropdown-menu>
                <van-dropdown-item
                    @change="onConfirm"
                    v-model="value1"
                    :options="option1"
                />
            </van-dropdown-menu>
        </div>
        <div class="loading">
            <van-loading v-show="isLoading" type="circular" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'homeHead',
    // 组件
    components: {},
    data() {
        return {
            language: '',
            value1: 0,
            option1: [
                { text: '中文', value: 0 },
                { text: '英文', value: 1 },
            ],
        }
    },
    props: {
        isLoading: Boolean,
    },
    computed: {},
    async created() {
        try {
            let res = localStorage.getItem('language')
            if (JSON.parse(res).language == 'zh') {
                this.value1 = 0
            }
            if (JSON.parse(res).language == 'en') {
                this.value1 = 1
            }
        } catch (err) {
            this.value1 = 0
        }
    },
    methods: {
        changeLanguage() {},
        onConfirm(e) {
            this.value1 = Number(e)
            if (e == 0) {
                localStorage.setItem('language', `{"language":"zh"}`)
                this.$translate.changeLanguage('chinese_simplified')
                this.$translate.execute()
            } else {
                localStorage.setItem('language', `{"language":"en"}`)
                this.$translate.changeLanguage('english')
                this.$translate.execute() //进行翻译
            }
        },
    },
}
</script>
<style>
@import './style/home-head.css';
</style>
