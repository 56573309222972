import Vue from "vue";
// 钱包类
let contractMapping = new Map();
export default class BasicWallet extends Vue {
    // 设置控制器
    async setContract (contractKey, contract) {
        contractMapping.set(contractKey, contract);
    }

    // 获取控制器
    async getContract (contractKey) {
        return contractMapping.get(contractKey)
    }

    // 调用钱包签名
    async signMsg () {
        return this.web3.web3.eth.personal.sign(this.web3.userAddress, this.web3.userAddress, 'password')
    }
}
Vue.prototype.basicWallet = new BasicWallet()