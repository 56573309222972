<template>
    <div class="homes">
        <homeHead :isLoading="isLoading"></homeHead>
        <div class="sto-buy" style="height: auto">
            <history message="资产"></history>
            <div>
                <div class="sto-fl">
                    <div class="sto-fl-box" style="margin: 25px 0">
                        <div class="sto-fl-box-li color1">
                            <div class="color">直推人数</div>
                            <div class="color1" style="margin-top: 10px">
                                <!-- 2023-07-18 23 01:30 -->
                            </div>
                        </div>
                        <div class="sto-fl-box-li color1">{{this.child}}人</div>
                    </div>
                    <div class="sto-fl-box" style="margin: 25px 0">
                        <div class="sto-fl-box-li color1">
                            <div class="color">团队人数</div>
                            <div class="color1" style="margin-top: 10px">
                                <!-- 2023-07-18 23 01:30 -->
                            </div>
                        </div>
                        <div class="sto-fl-box-li color1">{{this.children}}人</div>
                    </div>
                    <div class="sto-fl-box" style="margin: 25px 0">
                        <div class="sto-fl-box-li color1">
                            <div class="color">总业绩</div>
                            <div class="color1" style="margin-top: 10px">
                                2023-07-18 23 01:30
                            </div>
                        </div>
                        <div class="sto-fl-box-li color1">${{this.flow }}</div>
                    </div>
                    <!-- <div class="sto-fl-box" style="margin: 25px 0">
                        <div class="sto-fl-box-li color1">
                            <div class="color">私募业绩</div>
                            <div class="color1" style="margin-top: 10px">
                                2023-07-18 23 01:30
                            </div>
                        </div>
                        <div class="sto-fl-box-li color1">0.00$</div>
                    </div>
                    <div class="sto-fl-box" style="margin: 25px 0">
                        <div class="sto-fl-box-li color1">
                            <div class="color">sto 业绩</div>
                            <div class="color1" style="margin-top: 10px">
                                2023-07-18 23 01:30
                            </div>
                        </div>
                        <div class="sto-fl-box-li color1">0.00$</div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import homeHead from '../components/homeHead/homeHead'
import history from '../components/historyGo/historyGo.vue'
export default {
    name: 'statistics',
    // 组件
    components: {
        homeHead,
        history,
    },
    data() {
        return {
            active: 0,
            userUsdt: 0,
            isApprove: false,
            isIdo: false,
            isLoading: false,
            timer: 0,
            jumpIndex: {},
            urls: '',
            child:0,
            children:0,
            flow:0,
        }
    },
    mounted() {},
    computed: {},
    async created(res) {
        await this.getUserInfo();
    },
    methods: {
        // 获取用户直推和团队人数
        getUserInfo() {
            this.$api.get('/app/token/getUserInfo',{
            address: this.web3.userAddress,
            type: 'FEDT'
            })
                .then((data) => {
                    if(data.flag == "SUCCESS") {
                        // console.log('GET request response:', data)
                        this.child = data.payload.children;
                        this.children = data.payload.team_children;
                        this.flow = Number(data.payload.team_flow).toFixed(3);
                    } else {
                        this.userIsBind = false;
                    }
                })
                .catch((error) => {
                    // console.error('GET request error:', error)
                })
        },
    },
}
</script>
<style>
@import './style/statistics.css';
</style>
