import BigNumber from "bignumber.js";
// web3 公共库
export default class PublicMethod{

    approve_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';  //设置授权数量

    constructor(contract,abi,contractAddress,userAddress){
        this.contract = contract;
        this.abi = abi;
        this.contractAddress = contractAddress;
        this.userAddress = userAddress;
        if(this.contract && this.abi && this.contractAddress && this.userAddress){
            this.methods = new this.contract.Contract(this.abi,this.contractAddress,{from: this.userAddress}).methods
        }
    }
    
    // 根据地址，获取用户usdt余额
    async getBalance(userAddress,tokenDecimal){
        try {
            let result =  await this.methods.balanceOf(userAddress).call()
            if(result){
                return new BigNumber(result).div(new BigNumber(10).pow(tokenDecimal)).toFixed()
            }
        } catch(err){
            console.log(err)
        }
    }

    // 根据币或LP地址，获取币或LP发行总量
    async getTotalSupply(tokenDecimal) {
        try {
            let result = await this.methods.totalSupply().call();
            if(result) {
                result = new BigNumber(result).div(new BigNumber(10).pow(tokenDecimal)).toNumber();
                return result
            }
        } catch(err) {

        }
    }

    // 根据合约地址，查询是否获得了usdt授权额度
    async getIsApprove(userAddress,approveAddress) {
        try {
            let result = await this.methods.allowance(userAddress,approveAddress).call();
            if(result) {
                return new BigNumber(result).div(new BigNumber(10).pow(18)).toFixed()
            }
        } catch(err){
            return err
        }
    }

    // 进行授权
    async approve(contractAddress) {
        try {
            let result = await this.methods.approve(contractAddress, this.approve_amount).send();
            if(result) {
                return true;
            } 
        } catch(err) {
            return false;
        }
    }
} 