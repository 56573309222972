import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Web3 from "../src/components/basic/basic"
import ApiPlugin from './common/api'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'animate.css';
import translate from 'i18n-jsautotranslate'
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start()
Vue.prototype.$translate = translate
// 注入请求
Vue.use(ApiPlugin);

// 注入UI库
Vue.use(Vant);

// 挂载web3属性到全局
let web =  new Web3();
 await web.init()

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
